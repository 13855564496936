<template>
    <div class="dashboard-column" ref="column" :style="'width:' + width + 'px'">
        <div
                v-if="showBadge"
                class="scroll-up-badge tweets">
            <a href="javascript:void(0)" class="social-scanner-scroll-up"
               v-scroll-to="scrollToOptions"
            ><font-awesome-icon :icon="['fas', 'chevron-circle-up']" />&nbsp;New posts
            </a>
        </div>

        <div class="dashboard-column--title twitter-style disable-select">
            <div class="dashboard-column--title__inner">

                <span>{{ column.channel_name }}</span>
                <span>
                    <a
                            v-if="threadMode"
                            @click="exitThread"
                            href="javascript:void(0)"><font-awesome-icon :icon="['fas', 'arrow-alt-circle-left']"
                    /></a>
                    <a
                            v-else
                            v-scroll-to="scrollToOptions"
                            href="javascript:void(0)"><font-awesome-icon :icon="['fas', 'arrow-alt-circle-up']"
                    /></a>
                </span>

            </div>
        </div>
        
            <padlock-overlay
            :impID="impID"
            v-if="isLocked"
            class="blur"
            :instructions="true"
            ></padlock-overlay>
            <div
                    :id="'channel-'+column.id+'-'+layoutID"
                    class="dashboard-column--content scrollable" ref="scrollable">
                <div
                        :id="'channel-inner-'+column.id+'-'+layoutID"
                        ref="scroller">

                    <social-item
                            v-for="tweet in tweets"
                            :key="tweet.uid"
                            :tweet="tweet"
                            @show-thread="showThread"
                            :threadMode="threadMode"

                    ></social-item>

                    <loading-spinner v-if="fetchMode"></loading-spinner>

                    <div v-if="noItems && !fetchMode">
                    <div class="no-more-items"><font-awesome-icon :icon="['fas', 'exclamation-triangle']" /> No tweets found</div>
                    </div>

                </div>
            </div>
        
    </div>
</template>

<script>
import SocialItem from './SocialItem';
import { createNamespacedHelpers } from 'vuex';
import { mergeArraysByProp, searchForTweetChildren, sortDataBy } from '../../../../utility/helper-functions';
import LoadingSpinner from '../../../ui/LoadingSpinner';
import { scroller } from 'vue-scrollto/src/scrollTo';
import ScrollMixin from '../../../../mixins/scrollMixins';
import PadlockOverlay from '../../../../assets/PadlockOverlay.vue';

const tweetNS = createNamespacedHelpers('tweetdeck');

export default {
    props: ["column","tweetsReady","autoScrollOn","layoutID", "width", "colIndex", "isLocked", "impID"],
    mixins: [ScrollMixin],
    components: {
        SocialItem,
        LoadingSpinner,
        PadlockOverlay
    },
    created() {
        this.initializeLastTweetUpdateHandler();
        if(this.tweetsReady)
            this.getCurrentTweetsForColumn();
    },
    beforeDestroy: function () {
        this.unwatchHandler();
    },
    mounted() {
        this.setupScrollListener();
    },
    computed: {
        ...tweetNS.mapGetters(["lastTweetUpdate", "lastChannelHandleUpdate"]),
        showBadge() {
            return this.newTweet && this.isScrolledDown;
        }

    },
    data() {
        return {
            tweets: [],
            threadMode: false,
            threadsFetchedFromServer: [],
            initialFetchCount: 10,
            lastNumberOfTweets: 0,
			limit: 10,
			newTweet: false,
			scrollToOptions: {
				el: '#channel-inner-'+this.column.id+'-'+this.layoutID,
				container: '#channel-'+this.column.id+'-'+this.layoutID,
				duration: 500,
				lazy: false,
				easing: 'linear',
				offset: 0,
				force: true,
				x: false,
				y: true
			},
            loading: false,
            unwatchHandler: null,
            noItems: false
        }
    },
    methods: {
        initializeLastTweetUpdateHandler: function () {
            this.unwatchHandler = this.$store.watch(
                state => state.tweetdeck.lastTweetUpdate,
                (newValue, oldValue) => {
                    this.lastTweetUpdateLogic(newValue, oldValue);
                }
            );
        },
        lastTweetUpdateLogic: function (v2, v1) {
            if(v2 && v1 && v1.uid !== v2.uid) {
                if(!this.threadMode &&
                    this.lastTweetUpdate.channel_id === this.column.id) {
                    const index = this.tweets.findIndex(tweet => {
                        // console.log(tweet.uid === this.lastTweetUpdate.uid)
                        return tweet.uid === this.lastTweetUpdate.uid
                    });

                    this.lastTweetUpdate.new = true;

                    if(index > -1) {
                        this.tweets.splice(index, 1, this.lastTweetUpdate);
                    }
                    else {
                        this.tweets.unshift(this.lastTweetUpdate);
                    }

                    if(this.autoScrollOn) {
                        // const positionX = this.$refs.column.getBoundingClientRect().x;
                        this.$emit("auto-scroll-to", Math.round(this.colIndex * this.width) /*positionX - 80*/);
                        // console.log(positionX - 80,this.$refs.column.getBoundingClientRect());
                        // console.log("emit", this.colIndex);
                        setTimeout(() => {
                            const firstScrollTo = scroller();
                            firstScrollTo('#channel-inner-'+this.column.id+'-'+this.layoutID, 500, {
                                container: '#channel-'+this.column.id+'-'+this.layoutID,
                                lazy: false,
                                easing: 'linear',
                                offset: -5,
                                force: true,
                                x: false,
                                y: true
                            });
                        },600);
                    }


                }

                if(this.lastTweetUpdate.channel_id === this.column.id) {

                    this.newTweet = true;
                    this.lastNumberOfTweets = this.tweets.length;

                }
            }


        },
        onScroll() {
            const scrollTop = this.$refs.scrollable.scrollTop;
            const height = this.$refs.scrollable.getBoundingClientRect().height;
            const scrollHeight = this.$refs.scrollable.scrollHeight;

            // console.log(this.$refs.scrollable.scrollTop, this.$refs.scrollable.getBoundingClientRect().height, this.$refs.scrollable.scrollHeight);
            
            if ((scrollTop + height >= scrollHeight - 200) &&
            !this.fetchMode && 
            !this.noMoreItems) {                 
                // console.log("scroll-down-enough");
                this.fetchMode = true;
                window.bootUp.iDB.getMoreRecords({
                    name: "tweets-load-more",
                    channelID: this.column.id,
                    offset: this.tweets.length,
                    limit: this.limit,
                    oldestTweetTime: this.tweets[this.tweets.length - 1].created_at
                }).then(response => {
                    // console.log("to render in col",response);
                    let allTweets = mergeArraysByProp(this.tweets, response, "uid");
                    sortDataBy(allTweets, "created_at", "timeDESC");
                    this.tweets = allTweets;
                    this.fetchMode = false;
                })
            }                
            
            if(scrollTop > 100) {
                // console.log("scrolled down");
                this.isScrolledDown = true;
            }else{
                this.isScrolledDown = false;
                // console.log("scrolled up");
                this.newTweet = false;
            }
        },
        getCurrentTweetsForColumn() {

            window.bootUp.iDB.getTweetsByBoard(this.column.board_id, false).then(response => {

                this.tweets = response.filter(tweet => tweet.channel_id === this.column.id);
                if(!this.tweets.length) {
                    this.noItems = true;
                    this.fetchMode = false;
                    return;
                }
                    

                sortDataBy(this.tweets, "created_at", "timeDESC");

                const count = this.lastNumberOfTweets > this.initialFetchCount ? this.lastNumberOfTweets : this.initialFetchCount;

                this.tweets.splice(count,this.tweets.length);

                this.fetchMode = false;
            });
        },
        async showThread(tweet) {

            this.fetchMode = true;

            this.removeScrollListener();

            const boardID = tweet.board_id;
            const channelID = tweet.channel_id;
            let originalID = tweet.uid;

            if(tweet.thread)
                originalID = tweet.thread;

            let result = [];

            const queryObject = {
                name: "fetch-tweet-by-uid",
                objStore: "tweet_tweets",
                prop: 'uid',
                value: originalID
            };

            const tweetFromiDB = await window.bootUp.iDB.getRecordByProperty(queryObject);

            // console.log("tweetFromiDB", tweetFromiDB);


            if(tweetFromiDB.length) {

                const id = tweetFromiDB[0].uid;
                // console.log("id", id);
                const parents = await window.bootUp.iDB.getTweetsInThreadUp(id, []);

                parents.reverse();

                // console.log(parents);
                if(parents[0]) {
                    result.push(parents[0]);
                    // console.log('start',result);
                    const thread = await window.bootUp.iDB.getTweetsInThreadDown(parents[0].uid);

                    result = [...result, ...thread];

                    result = result.map(element => JSON.parse(JSON.stringify(element)));

                    // result.forEach(element => {
                    //     element.board_id = +boardID;
                    //     element.channel_id = +channelID;
                    //     element.uid = `${boardID}_${channelID}_${element.tweet_id}`
                    // });

                    // console.log("result",result);
                    //TO DO
                    this.tweets = result;
                    this.threadMode = true;


                }
                else{
                    //we need to check if there are any priorly saved replies, tweets in component before checking upstream replies / tweets fetched from the server
                    const idDBParentsHighestRecord = parents[1];


                    const lowestLocalRecord = this.threadsFetchedFromServer.find(tweet => tweet.uid === idDBParentsHighestRecord.thread);

                    /////////////console.log(lowestLocalRecord);


                    if(lowestLocalRecord) {
                        let tweetArray = [];

                        const getParents = pid => {
                            const data = this.threadsFetchedFromServer.find(tweet => tweet.uid === pid);

                            tweetArray.push(data);

                            if(data && data.thread) {
                                const parentID = data.thread;
                                return getParents(parentID, tweetArray);
                            }else{
                                return tweetArray;
                            }
                        }

                        let opTweet = {};
                        let startTweet;

                        if(lowestLocalRecord.thread) {
                            getParents(lowestLocalRecord.thread);
                            opTweet = tweetArray[tweetArray.length-1];
                            startTweet = opTweet.thread;
                        }else{
                            opTweet = lowestLocalRecord;
                            startTweet = opTweet.uid;
                        }


                        let results = [opTweet];

                        // console.log(opTweet);

                        searchForTweetChildren(this.threadsFetchedFromServer, startTweet, results);

                        // console.log('fetch records locally', results);

                        //TO DO
                        //RENDER
                        this.tweets = result;
                        this.threadMode = true;


                    }else{
                        // console.log('need to make a server call 1');

                        const response = await window.bootUp.iDB.getThreadFromServer({
                            channelID: channelID,
                            tweet_id: tweet.tweet_id
                        });

                        if(response) {
                            // console.log("server resp",response);
                            response.forEach(twt => {
                                twt.board_id = +boardID;
                                twt.channel_id = +channelID;
                                twt.uid = `${boardID}_${channelID}_${twt.tweet_id}`;
                                this.threadsFetchedFromServer = this.threadsFetchedFromServer.filter(element => element.uid !== twt.uid);

                                this.threadsFetchedFromServer.push(twt);
                            });

                        }

                        this.tweets = response;
                        this.threadMode = true;

                    }

                }
            }
            else{
                // console.log('need to make a server call 2');

                const response = await window.bootUp.iDB.getThreadFromServer({
                    channelID: channelID,
                    tweet_id: tweet.tweet_id
                });

                if(response) {
                    response.forEach(twt => {
                        twt.board_id = +boardID;
                        twt.channel_id = +channelID;
                        twt.uid = `${boardID}_${channelID}_${twt.tweet_id}`;
                        this.threadsFetchedFromServer = this.threadsFetchedFromServer.filter(element => element.uid !== twt.uid);

                        this.threadsFetchedFromServer.push(twt);
                    });

                }

                this.tweets = response;
                this.threadMode = true;
            }

            this.fetchMode = false;

        },
        exitThread() {
            this.threadMode = false;
            this.tweets = this.getCurrentTweetsForColumn();
        }
    },
    watch: {
        // lastTweetUpdate(v1, v2) {
        //     if(v2 && v1.uid !== v2.uid) {
        //         if(!this.threadMode &&
        //         this.lastTweetUpdate.channel_id === this.column.id) {
        //             const index = this.tweets.indexOf(tweet => {
        //                 // console.log(tweet.uid === this.lastTweetUpdate.uid)
        //                 return tweet.uid === this.lastTweetUpdate.uid
        //             });

        //             this.lastTweetUpdate.new = true;

        //             if(index > -1) {
        //                 this.tweets.splice(index, 1, this.lastTweetUpdate);
        //             }
        //             else {
        //                 this.tweets.unshift(this.lastTweetUpdate);
        //             }


        //         }
        //     }

        //     if(!this.threadMode &&
        //         this.lastTweetUpdate.channel_id === this.column.id) {

        //         this.newTweet = true;
        //         this.lastNumberOfTweets = this.tweets.length;

        //         if(this.autoScrollOn) {
        //             const positionX = this.$refs.column.getBoundingClientRect().x;
        //             this.$emit("auto-scroll-to", positionX - 80);
        //             // console.log(positionX);

        //             setTimeout(() => {
        //                 const firstScrollTo = scroller();
        //                 firstScrollTo('#channel-inner-'+this.column.id+'-'+this.layoutID, 500, {
        //                     container: '#channel-'+this.column.id+'-'+this.layoutID,
        //                     lazy: false,
        //                     easing: 'linear',
        //                     offset: -5,
        //                     force: true,
        //                     x: false,
        //                     y: true
        //                 });
        //             },600);
        //         }


        //     }



        // },
        tweetsReady() {
            this.getCurrentTweetsForColumn();
        },
        lastHandleUpdate(nVal) {
            // console.log("handle update", nVal);
            if(nVal.deleted_at)
                this.tweets = this.tweets.filter(tw => tw.author.screenName.toLowerCase() !== nVal.handle.toLowerCase());
        },
        lastChannelHandleUpdate(nVal) {
            
            if(nVal.deleted_at && 
            nVal.channel_id === this.column.id) {
                this.tweets = this.tweets.filter(tw => tw.author.screenName.toLowerCase() !== nVal.handle.toLowerCase());
            }
                
        }
    }
}
</script>
